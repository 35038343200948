import { Link } from "gatsby"
import React from "react"
import Wrap from "../utilities/Wrap"
import { AnimatePresence, motion } from "framer-motion"
import Good from "../../images/svgs/Good.svg"
import Chaos from "../../images/svgs/Chaos.svg"
import ChaosVideo from "../components/ChaosVideo"
import { siteTitleHolder } from "./Title.module.scss"

const titleVariants = {
  home: {
    width: "100%",
    y: "10vh",
    x: "-50%",
    left: "50%",
  },
  notHome: {
    y: "0.5rem",
    width: "30rem",
    x: "0.5rem",
    left: "0%",
  },
}

const goodVariants = {
  home: {
    x: "10vw",
  },
  notHome: {
    x: "0vw",
  },
}

const chaosVariants = {
  home: {
    x: "-10vw",
    y: "100%",
  },
  notHome: {
    x: "0vw",
    y: "0%",
  },
}

const fadeVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

export default function Title({ isHome }) {
  let variant = isHome ? "home" : "notHome"

  return (
    <motion.div
      className={siteTitleHolder}
      variants={titleVariants}
      initial={false}
      animate={variant}
    >
      <Wrap
        condition={isHome}
        wrapper={children => <h1>{children}</h1>}
        altWrapper={children => <h2>{children}</h2>}
      >
        Good Chaos
      </Wrap>

      <Link title="Back to the Good Chaos Homepage" to="/">
        <div className="goodChaos">
          <motion.div
            className="good"
            variants={goodVariants}
            initial={false}
            animate={variant}
          >
            <Good />
          </motion.div>
          <motion.div
            className="chaos"
            variants={chaosVariants}
            initial={false}
            animate={variant}
          >
            <AnimatePresence>
              <motion.div
                key="svg"
                initial="hidden"
                variants={fadeVariants}
                animate="visible"
                exit="hidden"
                className="chaosSvgHolder"
              >
                <Chaos />
              </motion.div>
              {isHome && (
                <motion.div
                  key="video"
                  initial="hidden"
                  variants={fadeVariants}
                  animate="visible"
                  exit="hidden"
                  className="chaosVideoHolder"
                >
                  <Chaos />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </Link>
    </motion.div>
  )
}
