import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { footerHolder } from "./Footer.module.scss"

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <div className="logos">
        <div className="theCreativesLogo">
          GoodChaos is a member of The Creatives
          <StaticImage
            src="../../images/the-creative-logo.png"
            alt="Good Chaos is a member of The Creatives"
            width={150}
          />
        </div>
        <div className="ukGlobalScreenFund">
          <a href="https://www.bfi.org.uk/get-funding-support/funding-support-international-activity/uk-global-screen-fund">
            <StaticImage
              src="../../images/uk-global-screen-fund.png"
              alt="Supported by the UK Global Screen Fund"
              width={200}
            />
          </a>
        </div>
      </div>
      <ul className="footerLinks">
        <li>© {new Date().getFullYear()} GoodChaos</li>
        <li>
          <a href="mailto:info@goodchaos.co.uk" title="Email Good Chaos">
            info@goodchaos.co.uk
          </a>
        </li>
        <li>
          <Link to="/privacy" title="Privacy Statement">
            Privacy Statement
          </Link>
        </li>
      </ul>
      <a
        href="mailto:ben@lookupstudios.co.uk"
        title="Look Up!"
        className="credit"
      >
        Site by Look Up!
      </a>
    </footer>
  )
}
