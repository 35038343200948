import { useState, useEffect } from 'react'

export default function useIsPage(page, slug) {
  const [isPage, setIsPage] = useState('/' + page === slug)

  useEffect(()=>{
    setIsPage('/' + page === slug)
  }, [setIsPage, slug, page])

  return isPage
}
