import React from 'react'
import Nav from './Nav'
import Title from './Title'
import {headerHolder} from './Header.module.scss'

export default function Header({isHome}) {

  return (
    <header className={headerHolder}>
      <Title isHome={isHome} />
      <Nav />
    </header>
  )
}
