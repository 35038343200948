import React from "react";
import "../../styles/normalize.css";
import "../../styles/styles.scss";

import Header from "./Header";
import Footer from "./Footer";
import { MotionConfig, AnimatePresence, motion } from "framer-motion";

import useIsPage from "../../hooks/useIsPage";

import hydrateImages from "../utilities/hydrateImages";
import CookieBanner from "../components/CookieBanner";
import {
   LeftSpeckles,
   RightSpeckles,
   TopSpeckles,
} from "../components/Speckles";

const transition = {
   type: "tween",
   duration: 0.8,
   ease: "anticipate",
};

const mainVariants = {
   pre: {
      opacity: 0,
      y: 30,
   },
   visible: {
      opacity: 1,
      y: 0,
      transition: {
         duration: 0.5,
      },
   },
   post: {
      y: -30,
      opacity: 0,
   },
};

const Layout = ({ children, location }) => {
   const isHome = useIsPage("", location?.pathname);
   // const isAbout = useIsPage('about', location?.pathname)

   return (
      <MotionConfig transition={transition}>
         <div className="pageHolder">
            <Header isHome={isHome} />
            <AnimatePresence
               exitBeforeEnter={true}
               onExitComplete={() => setTimeout(() => hydrateImages(), 100)}
            >
               <motion.main
                  key={location.pathname}
                  variants={mainVariants}
                  initial="pre"
                  animate="visible"
                  exit="post"
               >
                  {children}
               </motion.main>
            </AnimatePresence>
            <Footer />
            <CookieBanner />

            <TopSpeckles />
            {!isHome && <LeftSpeckles />}
            {!isHome && <RightSpeckles />}
         </div>
      </MotionConfig>
   );
};

export default Layout;
