import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { specklesHolder } from './Speckles.module.scss'

export function TopSpeckles() {
  return (
    <StaticImage className={`${specklesHolder} topSpeckles`} src="../../images/speckles/top-speckles.jpg"  alt="" />
  )
}
export function LeftSpeckles() {
  return (
    <StaticImage className={`${specklesHolder} leftSpeckles`} src="../../images/speckles/left-speckles.jpg"  alt="" />
  )
}
export function RightSpeckles() {
  return (
    <StaticImage className={`${specklesHolder} rightSpeckles`} src="../../images/speckles/right-speckles.jpg"  alt="" />
  )
}