import React, { useState } from "react"
import { Link } from "gatsby"
import { navHolder } from "./Nav.module.scss"
import useIsSmallScreen from "../../hooks/useIsSmallScreen"
import { AnimatePresence, motion } from "framer-motion"
import "./hamburgers.min.css"

const variants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  post: {
    opacity: 0,
    x: "100vw",
    transition: {
      type: "tween",
      duration: 0.5,
      delay: 0.25,
    },
  },
}

export default function Nav() {
  const [hovered, setHovered] = useState(false)
  const isSmallScreen = useIsSmallScreen(860)
  const [navOpen, setNavOpen] = useState(false)

  return (
    <nav className={navHolder}>
      {isSmallScreen && (
        <div className="burger">
          <button
            className={`hamburger hamburger--slider ${
              navOpen ? "is-active" : ""
            }`}
            type="button"
            onClick={() => setNavOpen(!navOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
      )}

      <AnimatePresence initial={false}>
        {(!isSmallScreen || navOpen) && (
          <motion.ul
            variants={variants}
            initial="post"
            animate="visible"
            exit="post"
            key="linksHolder"
            className={`linksHolder ${
              isSmallScreen ? "smallScreenNavHolder" : ""
            }`}
            onClick={() => setNavOpen(false)}
            role="button"
          >
            {isSmallScreen && (
              <li>
                <Link
                  to="/"
                  title="Home Good Chaos"
                  activeClassName="active"
                  onPointerEnter={() => setHovered(true)}
                  onPointerOut={() => setHovered(false)}
                  className={`${hovered ? "hovered" : ""}`}
                >
                  Home
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/about"
                title="About Good Chaos"
                activeClassName="active"
                onPointerEnter={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                className={`${hovered ? "hovered" : ""}`}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/who-we-are"
                title="Who We Are"
                activeClassName="active"
                onPointerEnter={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                className={`${hovered ? "hovered" : ""}`}
              >
                Who We Are
              </Link>
            </li>
            <li>
              <Link
                to="/productions"
                title="Good Chaos Productions"
                activeClassName="active"
                onPointerEnter={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                className={`${hovered ? "hovered" : ""}`}
              >
                Productions
              </Link>
            </li>
            <li>
              <Link
                to="/news"
                title="Good Chaos News"
                activeClassName="active"
                onPointerEnter={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                className={`${hovered ? "hovered" : ""}`}
              >
                News
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                title="Contact Good Chaos"
                activeClassName="active"
                onPointerEnter={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                className={`${hovered ? "hovered" : ""}`}
              >
                Contact
              </Link>
            </li>
          </motion.ul>
        )}
      </AnimatePresence>
    </nav>
  )
}
